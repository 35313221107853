import { call, put, select, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import localForage from 'localforage'
import { getUserId, getUserToken, getOtpToken } from './selectors'
import { showSnackbar } from '../snackbar/actions'
import {
  SIGN_IN,
  SEND_OTP,
  VERIFY_OTP,
  RESET_PASSWORD,
  GET_USER_LOCATION,
  resetAccountPasswordSuccess,
  resetAccountPasswordError,
  signInSuccess,
  signInError,
  LOGOUT,
  logoutError,
  logoutSuccess,
  sendOTPSuccess,
  sendOTPError,
  verifyOTPSuccess,
  verifyOTPError,
  saveUserLocation
} from './actions'

function* signIn({ email, password, history }) {
  try {
    const response = yield call(api.signIn, email, password)

    const roles = response.authority.roles
    if (
      roles.includes('CRM_ADMIN') ||
      roles.includes('CRM_MANAGER') ||
      roles.includes('CRM_AGENT') ||
      roles.includes('CRM_CHAT_AGENT') ||
      roles.includes('CRM_FULFILLMENT_AGENT')
    ) {
      yield put(signInSuccess(response))

      roles.includes('ADMIN') || roles.includes('LEAD_MANAGER')
        ? history.push('/lead-dashboard')
        : roles.includes('PORTFOLIO_MANAGER')
        ? history.push('/portfolio-mgt')
        : history.push('/lead')
    } else {
      yield put(showSnackbar('Access Denied', 'error'))
    }
  } catch (error) {
    yield put(signInError())
    if (error.response.data)
      yield put(showSnackbar(error.response.data.message, 'error'))
  }
}

function* logout({ history }) {
  try {
    localForage.clear()
    yield put(logoutSuccess())
    history.push('/login')
  } catch (error) {
    yield put(logoutError())
  }
}

function* resetPassword({ password, history }) {
  try {
    const id = yield select(getUserId)
    const token = yield select(getUserToken)
    yield call(api.resetPassword, token, password, id)

    yield put(resetAccountPasswordSuccess())
    yield put(showSnackbar('Password changed successfully', 'success'))
    history && history.push('/leads')
  } catch (error) {
    yield put(resetAccountPasswordError())
    yield put(showSnackbar(error.response.data.message, 'error'))
  }
}

function* sendOTPCode({ payload }) {
  try {
    const response = yield call(api.sendOTP, payload)
    yield put(showSnackbar(response.message, 'success'))
    yield put(sendOTPSuccess(response.token))
  } catch (error) {
    yield put(sendOTPError())
  }
}

function* verifyOTPCode({ otp, history }) {
  try {
    const token = yield select(getOtpToken)
    const response = yield call(api.verifyOTP, token, otp)
    yield put(verifyOTPSuccess(response))

    if (response.user) {
      history.push('/leads')
    }
  } catch (error) {
    yield put(verifyOTPError())
    yield put(showSnackbar('Invalid verfication code', 'error'))
  }
}
function* getUserLocation() {
  try {
    const response = yield call(api.fetchUserLocation)

    yield put(saveUserLocation(response))
  } catch (error) {}
}

function* watchSignInSaga() {
  yield takeLatest(SIGN_IN, signIn)
}
function* watchGetUserLocation() {
  yield takeLatest(GET_USER_LOCATION, getUserLocation)
}

function* watchLogoutSaga() {
  yield takeLatest(LOGOUT, logout)
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

function* watchSendOTP() {
  yield takeLatest(SEND_OTP, sendOTPCode)
}

function* watchVerifyOTPCode() {
  yield takeLatest(VERIFY_OTP, verifyOTPCode)
}

const authSagas = [
  watchSignInSaga(),
  watchLogoutSaga(),
  watchResetPassword(),
  watchGetUserLocation(),
  watchSendOTP(),
  watchVerifyOTPCode()
]

export default authSagas
