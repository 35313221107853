import React, { useCallback, useEffect, useState } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import useStyles from './styles'
import ClipboardIcon from './svg/Clipboard'
import CheckMarkIcon from './svg/CheckMark'
import CheckMarkIconPass from './svg/GreenCheckMark'
import PhoneIcon from './svg/Phone'
import EnvelopeIcon from './svg/Envelope'
import ProfileIcon from './svg/Profile'
import QuestionMarkIcon from './svg/QuestionMark'
import PrinterIcon from './svg/Printer'

import Popover from '@material-ui/core/Popover'
import Pagination from '@material-ui/lab/Pagination'
import ChevronLeftIcon from './svg/ChevronLeft'
import CarItem from './CarItem'
import LoanCarItem from './LoanCarItem'
import Loader from '../../../components/Loader'
import { useHistory, useLocation } from 'react-router-dom'
import TitleWrapper from '../../Leads/LeadDetails/LeadActions/TitleWrapper'

import Reminder from './SetReminderForLoan'
import { useTranslation } from 'react-i18next'

import CaretDown from './svg/Caret'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import {
  getUserToken,
  getUserId,
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent
} from '../../../store/auth/selectors'
import {
  carsFromStore,
  leadNotes,
  leadFromStore,
  leadNotesSummary,
  loanNotePagination,
  getActivityArray
} from '../../../store/leads/selectors'
import { useParams } from 'react-router'
import {
  getCity,
  getStates,
  getHomeCity
} from '../../../store/location/actions'
import Checkbox from '@material-ui/core/Checkbox'
import {
  getAllCities,
  getAllStates,
  getAllHomeCities
} from '../../../store/location/selectors'
import { showSnackbar } from '../../../store/snackbar/actions'
import { Link } from 'react-router-dom'
import {
  addLeadNote,
  getLeadNotes,
  getAllVehicleDetails,
  getCarData,
  updateLead,
  getCurrentActivity
} from '../../../store/leads/actions'

import { makeStyles } from '@material-ui/core'
import Select from '../../../components/CustomizedSelect/Select'
interface ILoanDetails {
  user: {
    firstName: string
    lastName: string
    email: string
    phone: string
    bankStatementSource: string
    isBankStatementCollected: any
    bankStatementBank?: string
  }
  carName: string
  isDepositPaid: any
  offers: any
  depositPayment: any
}

interface ILoanDetailPathParam {
  id: string
}
interface TabId {
  id: '1'
}

const LoanDetails = (props: any) => {
  const classes = useStyles()
  const notesForLead = useSelector(leadNotes)
  const summaryNotesForLead = useSelector(leadNotesSummary)
  const { id } = useParams<ILoanDetailPathParam>()
  const cars = useSelector(carsFromStore)
  const token = useSelector(getUserToken)
  const [userLoanNote, setUserNoteText] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [leadIdFromLoanId, setLeadIdFromLoanId] = React.useState('')
  const [loanType, setLoanType] = React.useState('')
  const [accountName, setAccountName] = React.useState('')
  const [userSummaryLoanNote, setSummaryLoanText] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [loanDetails, setLoanDetails] = useState<any>()
  const [tabId, setTabId] = useState('1')
  const [car, setCar] = useState('')
  const selectedCountry = useSelector(getSelectedCountry)
  const authorId = useSelector(getUserId)
  const dispatch = useDispatch()
  const lead = useSelector(leadFromStore)
  const [agents, setAgents] = React.useState([] as any)
  const [uiDataFromFilter, setUiDataFromFilter] = React.useState([] as any)
  const [officeState, setOfficeState] = React.useState('')
  const [userComment, setUserComment] = useState('')

  const [loanUser, setLoanUser] = React.useState([] as any)
  const userIsAgent = useSelector(isAgent)
  const [customerAccept, setCustomerAccept] = React.useState(false)
  const [removeCheck, setCheckBox] = React.useState(false)
  const [loanStage, setLoanStage] = React.useState(false)
  const [homeCity, setHomeCity] = React.useState('')
  const currency = useSelector(getSelectedCountryCurrency)
  const cities = useSelector(getAllCities)
  const cities2 = useSelector(getAllHomeCities)
  let history = useHistory()
  let location = useLocation()
  const states = useSelector(getAllStates)
  const states2 = useSelector(getAllStates)
  const saveLeadNote = (event?: any) => {
    setUserNoteText(event.target.value)
  }
  const saveLeadComment = (event?: any) => {
    setUserComment(event.target.value)
  }

  const sendLeadNote = (type: string) => {
    if (userLoanNote == '') {
      dispatch(showSnackbar('Please type in a note', 'error'))
      return
    }
    let data = {
      author_id: authorId,
      text: type == 'LOAN_NOTE' ? userLoanNote : userSummaryLoanNote,
      lead_id: leadIdFromLoanId,
      type: type,
      comment: userComment
    }
    setCheckBox(false)
    setUserNoteText('')
    setSummaryLoanText('')
    dispatch(addLeadNote(data))
    setTimeout(() => {
      dispatch(getLeadNotes(leadIdFromLoanId, 'LOAN_NOTE', 1))
    }, 2000)
  }

  const changeTab = (id: any) => {
    setTabId(id)
  }
  const addNoteField = () => {
    setCheckBox(!removeCheck)
  }
  const clearTextArea = () => {
    setUserNoteText('')
  }

  const fetchCarDetail = useCallback(
    async (carID) => {
      try {
        const response = await api.fetchOneCar(token, carID)
        // console.log(response, 'car fro ')
        setCar(response)
        return response
      } catch (e) {
        showSnackbar('Unable to fetch loan details', 'error')
      }
    },
    [id, token]
  )

  const fetchBankId = useCallback(
    async (bankId) => {
      try {
        const response = await api.fetchBankId(token, bankId)
        // console.log(response, 'bank ID')
        setAccountName(response.bankName)
      } catch (e) { }
    },
    [id, token]
  )

  const getLeadDetails = useCallback(
    async (leadId) => {
      try {
        const response = await api.fetchLead(token, leadId)
        //  console.log(response, 'lead details')
        setLeadIdFromLoanId(response.id)
        setAgents(response.multipleAgents)
      } catch (e) { }
    },
    [id, token]
  )

  const getLoanUserData = useCallback(
    async (uuid,country) => {
      try {
        const response = await api.fetchLoanUser(token, uuid,country)
        //  console.log(response.loans, 'loan user details')

        let carIds = [] as any

        response?.loanLeads.filter((loan: any) => {
          carIds.push(loan.carId)
        })
        setLoanUser(response?.loanLeads)
        dispatch(getAllVehicleDetails(carIds))
        // console.log(carIds, 'here its')
      } catch (e) { }
    },
    [id, token]
  )

  const fetchLoanDetails = useCallback(
    async (loanId) => {
      try {
        const response = await api.fetchSingleLoan(token, loanId)
        // console.log(response, 'customer one')

        let offersArray = response?.offers ?? []

        offersArray.sort((a: any, b: any) =>
          a.userAcceptedOffer > b.userAcceptedOffer ? 1 : -1
        )

        setLoanDetails(response)

        getLoanUserData(response?.customer?.userId, response?.country)

        response.offers = offersArray.reverse()
        fetchCarDetail(response.carId)
        if (response?.user?.selectedBankId) {
          fetchBankId(response.user.selectedBankId)
        }

        let status = false

        let accept = response.offers.filter((data: any) => {
          if (data.userAcceptedOffer) {
            status = data.userAcceptedOffer
          }
        })

        setCustomerAccept(status)

        setLoader(false)
      } catch (e) {
        showSnackbar('Unable to fetch loan details', 'error')
      }
    },
    [id, token]
  )

  useEffect(() => {
    // console.log(id, 'user_id')
    let loanTypeFromStorage = localStorage.getItem('loanType') as string
    setLoanType(loanTypeFromStorage)

    setLoader(true)
    let leadId = ''
    let idOfUser = id
    // console.log(loanId, 'loanId')

    if (location.search) {
      leadId = location.search.split('=')[1]
    } else {
      idOfUser = id.split('?')[0]
      leadId = id.split('=')[1]
    }

    setUserId(idOfUser)
    getLeadDetails(leadId)
    fetchLoanDetails(idOfUser)
    dispatch(getLeadNotes(leadId, 'LOAN_NOTE', 1))

    // console.log( props.match, 'id of user')

    // dispatch(getLeadNotes(id, 'LOAN_NOTE'))
  }, [location])

  useEffect(() => {
    if (cars) {
      let uiData = loanUser.filter((data: any) => {
        cars.filter((carData: any) => {
          if (carData.id == data.carId) {
            data.dataProvided = carData
          }
        })
        return data
      })
      setUiDataFromFilter(uiData)
      // console.log(uiData, 'carsssssssss are here')
    }
  }, [cars])

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        setLoanStage(false)
      }
    }
  }, [loader])

  const getLoanStage = (id: string, car: any) => {
    setLoanStage(true)
    setCar(car)
    history.push(`/loans/${id}?lead_id=${leadIdFromLoanId}`)
  }

  const { t } = useTranslation()

  return (
    <WithSideMenu>
      {userId !== 'undefined' ? (
        <>
          {uiDataFromFilter.length < 1 || loader ? (
            <div>
              <div
                className={classes.userDetails}
                style={{ minHeight: '100vh' }}
              >
                <Loader />
              </div>
            </div>
          ) : (
            <div className={classes.container}>
              <header className={classes.header}>
                {loanStage ? (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLoanStage(false)
                      // history.goBack()
                    }}
                    // onClick={history.goBack}
                    className={`${classes.headerSection} ${classes.backBtn}`}
                  >
                    <ChevronLeftIcon />
                    {t('CARS OF INTEREST')}
                  </div>
                ) : (
                  <>
                    {loanType === 'REGULAR' ? (
                      <Link
                        to="/loans"
                        // onClick={history.goBack}
                        className={`${classes.headerSection} ${classes.backBtn}`}
                      >
                        <ChevronLeftIcon />
                        {t('LOAN LEADS')}
                      </Link>
                    ) : null}
                    {loanType === 'DSA' ? (
                      <Link
                        to="/dsa"
                        // onClick={history.goBack}
                        className={`${classes.headerSection} ${classes.backBtn}`}
                      >
                        <ChevronLeftIcon />
                        {t('dsa_loans')}
                      </Link>
                    ) : null}
                    {loanType === 'FRANCHISE' ? (
                      <Link
                        to="/franchise"
                        // onClick={history.goBack}
                        className={`${classes.headerSection} ${classes.backBtn}`}
                      >
                        <ChevronLeftIcon />
                        {t('franchise_loans')}
                      </Link>
                    ) : null}
                  </>
                )}

                <div className={classes.headerSection}>
                  <QuestionMarkIcon />
                  <div className={classes.nameInitials}>CT</div>
                </div>
              </header>
              <section className={classes.userDetails}>
                <div className={classes.userDetailsSection}>
                  <div className={classes.userDetailsTopLeft}>
                    <h1 className={classes.loanUserName}>
                      {!loanDetails
                        ? 'Xxxx Xxxx'
                        : `${loanDetails?.customer?.profile?.find((e:any) => e.name === 'firstName')?.value ?? ''} ${loanDetails?.customer?.profile?.find((e:any) => e.name === 'lastName')?.value ?? ''}`}
                    </h1>
                    <p>
                      <PhoneIcon />
                      &nbsp;
                      {!loanDetails ? 'xxx xxx-xxx' : loanDetails?.customer?.profile?.find((e:any) => e.name === 'phone' || e.name === 'telephone')?.value ?? ''}
                    </p>
                    <p>
                      <EnvelopeIcon />
                      &nbsp;
                      {!loanDetails
                        ? 'xxx@xxxxx.xxx'
                        : loanDetails?.customer?.profile?.find((e:any) => e.name === 'email')?.value ?? ''}
                    </p>
                  </div>
                  <div className={classes.userDetailsTopRight}>
                    {/* <div className={classes.currentPhase}>Deposit payment</div> */}
                    {/* <p className={classes.currentPhaseInfo}>5 days in this phase</p> */}
                    {/* <PrinterIcon style={{ marginRight: '1em' }} />
              <MenuIcon /> */}
                  </div>
                </div>
                <div className={classes.userDetailsSection}>
                  <p className={classes.agentName}>
                    <ProfileIcon />
                    &nbsp; {t('assignedTo')}:{' '}
                    <span>{agents?.map((agent: any) => agent.name)}</span>
                  </p>
                  {/* <div className={classes.userDetailsBottomRight}>
              <div className={classes.location}>
                <p>Customer's office location</p>

 
                <div style={{minWidth:'10em', marginRight:'1em'}}> 
  <Select  

defaultValue={stateValue}
onChange={handleOfficeStateChange}

options={states} />
  </div>
<div style={{minWidth:'10em'}}>
<Select  
defaultValue={officeCityValue}
onChange={handleOfficeCityChange} 

options={cities} />

</div>

              
                
                <select name="office_city" id="office_city">
                  <option value="">Select city</option>
                </select>
              </div>
              <div className={classes.location}>
                <p>Customer's home location</p>

 

<div style={{minWidth:'10em', marginRight:'1em'}}> 
  <Select  

onChange={handleHomeStateChange}
placeholder="state"
options={states2} />
  </div>
<div style={{minWidth:'10em'}}>
<Select  

onChange={handleHomeCityChange}
placeholder="city"
options={cities2} />

</div>
              
                
                <select name="office_city" id="office_city">
                  <option value="">Select city</option>
                </select>
              </div>

            </div> */}
                </div>
              </section>

              {!loanStage ? (
                <section className={classes.loanStatusContainer}>
                  <div style={{ background: 'white' }}>
                    <div style={{ paddingLeft: '1em', paddingTop: '1em' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        &nbsp;{t('carsInterest')}
                      </span>
                    </div>

                    <div className={classes.carLoansDiv}>
                      {uiDataFromFilter?.map((data: any) => (
                        <>
                          <LoanCarItem
                            car={data}
                            carName={data.carName}
                            getLoanStage={getLoanStage}
                            leadId={leadIdFromLoanId}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </section>
              ) : (
                <section className={classes.loanStatusContainer}>
                  <div className={classes.loanStatus}>
                    <p>
                      <CaretDown />
                      &nbsp;{t('loanStatus')}
                    </p>
                    <div className={classes.phases}>
                      <PhaseHeader
                        phaseName="DEPOSIT PAYMENT"
                        idPassed="1"
                        changeTabProp={() => changeTab('1')}
                        passMark={loanDetails?.equities?.length}
                        active={tabId === '1' ? true : false}
                      />
                      <PhaseHeader
                        phaseName="BANK ACCOUNT LINKING"
                        passMark={loanDetails?.user?.isBankStatementCollected}
                        active={tabId === '2' ? true : false}
                        idPassed="2"
                        changeTabProp={() => changeTab('2')}
                      />
                      <PhaseHeader
                        phaseName="LOAN OFFERS"
                        passMark={loanDetails?.offers.length > 0 ? true : false}
                        idPassed="3"
                        active={tabId === '3' ? true : false}
                        changeTabProp={() => changeTab('3')}
                      />
                      <PhaseHeader
                        phaseName="CUSTOMER ACCEPTANCE"
                        passMark={loanDetails?.offer}
                        active={tabId === '4' ? true : false}
                        idPassed="4"
                        changeTabProp={() => changeTab('4')}
                      />
                    </div>
                    <div className={classes.phaseDetails}>
                      <div className={classes.phaseDetailsItem}>
                        <h4>{t('SelectedCar')}</h4>
                        <CarItem car={car} carName={`${loanDetails?.car?.model?.make?.name ?? ''} ${loanDetails?.car?.model.name ?? ''} ${loanDetails?.car?.year ?? ''}`} />
                      </div>
                      {tabId == '1' ? (
                        <div className={classes.phaseDetailsItem}>
                          {loanDetails?.equities ? (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('DepositDetails')}
                              </h4>

                              <div className={classes.depositDetailBlock}>
                                <div>
                                  <div>
                                    <span style={{ padding: '0em' }}>
                                      {t('PaidAmount')}
                                    </span>
                                  </div>
                                  <span style={{ color: '#0F1A4F' }}>
                                    {currency}{' '}
                                    {loanDetails?.equities?.[0]?.amountPaid.toLocaleString()}
                                  </span>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                  <div>
                                    <span style={{ padding: '0em' }}>
                                      {t('PaymentReferenceNumber')}
                                    </span>
                                  </div>
                                  <span style={{ color: '#0F1A4F' }}>
                                    {
                                      loanDetails?.equities?.[0]?.fileUrl}
                                  </span>
                                </div>
                              </div>

                              {/* <div className={classes.saveNote}>
                  <button className={classes.reminderBtn}>
                    Send reminder to customer
                  </button>
                </div> */}
                            </div>
                          ) : (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('DepositDetails')}
                              </h4>
                              <EmptyContent text="No data" />
                            </div>
                          )}
                        </div>
                      ) : null}

                      {tabId == '2' ? (
                        <div className={classes.phaseDetailsItem}>
                          {loanDetails?.user?.isBankStatementCollected ? (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('CustomerBankAccount')}{' '}
                              </h4>

                              <div className={classes.depositDetailBlock}>
                                <div>
                                  <div>
                                    <span style={{ padding: '0em' }}>
                                      {t('CustomerBank')}
                                    </span>
                                  </div>

                                  {loanDetails?.user?.bankStatementBank ||
                                    accountName !== '' ? (
                                    <span style={{ color: '#0F1A4F' }}>
                                      {loanDetails?.user?.bankStatementBank
                                        ? loanDetails?.user.bankStatementBank
                                        : accountName}
                                    </span>
                                  ) : (
                                    <span style={{ color: '#0F1A4F' }}>
                                      {'N/A'}
                                    </span>
                                  )}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                  <div>
                                    <span style={{ padding: '0em' }}>
                                      {t('BankStatement')}
                                    </span>
                                  </div>
                                  <span style={{ color: '#0F1A4F' }}>
                                    {' '}
                                    {loanDetails?.user?.bankStatementSource}{' '}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('CustomerBankAccount')}{' '}
                              </h4>
                              <EmptyContent text="No data" />
                            </div>
                          )}
                        </div>
                      ) : null}
                      {tabId == '3' || tabId == '4' ? (
                        <div className={classes.phaseDetailsItem}>
                          {loanDetails?.offers.length > 0 ? (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('BankOffers')}
                              </h4>

                              {loanDetails?.offers?.map((offer: any) => (
                                <div
                                  className={classes.offersBlock}
                                  key={offer.id}
                                  style={{ marginBottom: '20px' }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <div className={classes.bankBlock}>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {offer?.partner?.name}{' '}
                                      </span>

                                      <div>
                                        <img src={offer?.partner?.logoUrl} height="20" />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px',
                                        marginLeft: '10px'
                                      }}
                                    >
                                      {offer.status == 'PENDING' ? (
                                        <div className={classes.pendingBlock}>
                                          <span style={{ color: '#0F1A4F' }}>
                                            {t('PENDING')}{' '}
                                          </span>
                                        </div>
                                      ) : null}
                                      {offer.status == 'REJECTED' ? (
                                        <div className={classes.declinedBlock}>
                                          <span>{offer?.declinedBy === 'CUSTOMER' ? t('CUSTOMER DECLINED') : t('DECLINED')} </span>
                                        </div>
                                      ) : null}

                                      {offer.status == 'ACCEPTED' ? (
                                        <div className={classes.approvedBlock}>
                                          <span>{t('APPROVED')} </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap'
                                    }}
                                  >
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px'
                                      }}
                                    >
                                      <div>
                                        <span style={{ padding: '0em' }}>
                                          {t('LoanAmount')}
                                        </span>
                                      </div>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {currency}{' '}
                                        {offer?.approvedAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px'
                                      }}
                                    >
                                      <div>
                                        <span style={{ padding: '0em' }}>
                                          {t('Downpayment')}
                                        </span>
                                      </div>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {currency}{' '}
                                        {offer?.equity.toLocaleString()}{' '}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px'
                                      }}
                                    >
                                      <div>
                                        <span style={{ padding: '0em' }}>
                                          {t('InterestRate')} (P/A)
                                        </span>
                                      </div>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {offer?.interest}%
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px'
                                      }}
                                    >
                                      <div>
                                        <span style={{ padding: '0em' }}>
                                          {t('Tenure (months)')}
                                        </span>
                                      </div>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {offer?.tenure}{' '}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        flexBasis: '33%',
                                        marginTop: '15px'
                                      }}
                                    >
                                      <div>
                                        <span style={{ padding: '0em' }}>
                                          {t('MonthlyEMI')}{' '}
                                        </span>
                                      </div>
                                      <span style={{ color: '#0F1A4F' }}>
                                        {currency}{' '}
                                        {offer?.monthlyRepayment.toLocaleString()}
                                      </span>
                                    </div>

                                    {(offer?.status == 'ACCEPTED' &&
                                      tabId == '4') ||
                                      (offer?.userAcceptedOffer &&
                                        offer?.status == 'ACCEPTED' &&
                                        tabId == '4') ? (
                                      <div
                                        style={{
                                          flexBasis: '33%',
                                          marginTop: '15px'
                                        }}
                                      >
                                        <div>
                                          <span style={{ padding: '0em' }}>
                                            {t('Accepted')}
                                          </span>
                                        </div>
                                        {(offer?.userAcceptedOffer &&
                                          offer?.status == 'APPROVED') ||
                                          (offer?.userAcceptedOffer &&
                                            offer?.status == 'ACCEPTED') ? (
                                          <div
                                            className={classes.acceptedBlock}
                                          >
                                            <span> {t('YES')} </span>
                                          </div>
                                        ) : (
                                          <div
                                            className={classes.declinedBlock}
                                          >
                                            <span> {t('NO')} </span>
                                          </div>
                                        )}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <h4
                                style={{
                                  color: '#A1AACE',
                                  fontWeight: 'normal'
                                }}
                              >
                                {t('BankOffers')}
                              </h4>
                              <EmptyContent text="No data" />
                            </div>
                          )}
                        </div>
                      ) : null}

                      <div className={classes.phaseDetailsItem}>
                        <h4>{t('AddNote')}</h4>
                        <Select
                          value={userLoanNote}
                          name=""
                          label={t('Add a Note')}
                          options={[
                            { label: t('Unanswered'), value: 'Unanswered' },
                            { label: t('Unreachable'), value: 'Unreachable' },
                            { label: t('Busy'), value: 'Busy' },
                            { label: t('Scheduled to sell'), value: 'Scheduled to sell' },
                            { label: t('Scheduled to buy'), value: 'Scheduled to buy' },
                            { label: t('Scheduled to fix'), value: 'Scheduled to fix' },
                            { label: t('Incorrect contact: To email'), value: 'Incorrect contact: To email' },
                            { label: 'Loan Lead: CP', value: 'Loan lead: CP' },
                            { label: 'Car Unavailable: Customer not interested', value: 'Car Unavailable: Customer not interested' },
                            { label: t('Car Unavailable: Customer requested for a replacement'), value: 'Car Unavailable: Customer requested for a replacement' },
                            { label: t('Client not contacted'), value: 'Client not contacted' },
                            { label: t('Successful Conversion'), value: 'Successfull Conversion' },
                            { label: t('Completed Profile'), value: 'Completed Profile' },
                            { label: t('Test'), value: 'Test' },
                            { label: t('Potential Client'), value: 'Potential Client' },
                            { label: t('Window Shopping'), value: 'Window Shopping' },
                            { label: t('Unavailable client'), value: 'Unavailable client' },
                            { label: t('Got a Unit'), value: 'Got a Unit' },
                            { label: t('Contacted via Whatsapp: Scheduled to buy'), value: 'Contacted via Whatsapp: Scheduled to buy' },
                            { label: t('Contacted by LM'), value: 'Contacted by LM' },
                            { label: t('Other'), value: 'Other' }
                                                  ]}
                          onSelect={(e: any) => { setUserNoteText(e.target.value) }}
                        />
                        <h4>{t('Comment')}</h4>
                        <textarea
                          className={classes.noteTextArea}
                          value={userComment}
                          onChange={(e) => saveLeadComment(e)}
                        />

                        <div
                          style={{
                            display: 'flex',
                            minHeight: '20px',
                            alignItems: 'center'
                          }}
                        >
                          {userIsAgent ? (
                            <div>
                              <Checkbox
                                checked={removeCheck}
                                onChange={(e) => addNoteField()}
                              />
                            </div>
                          ) : null}
                          {userIsAgent ? (
                            <div
                              onClick={(e) => addNoteField()}
                              style={{ cursor: 'pointer' }}
                            >
                              {t('SetReminder')}
                            </div>
                          ) : (
                            <div
                              style={{
                                minHeight: '30px',
                                background: '#f1f3f466',
                                color: '#a1aace',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px',
                                marginTop: '10px',
                                borderRadius: '5px'
                              }}
                            >
                              {t('LogAgentSaveReminder')}
                            </div>
                          )}
                        </div>

                        {removeCheck && userIsAgent ? (
                          <Reminder
                            loanNote={userLoanNote}
                            userId={userId}
                            id={leadIdFromLoanId}
                            clearText={() => clearTextArea()}
                            saveNote={() => addNoteField()}
                          />
                        ) : (
                          <div className={classes.saveNote}>
                            {userIsAgent ? (
                              <button
                                className={classes.saveNoteBtn}
                                onClick={() => sendLeadNote('LOAN_NOTE')}
                              >
                                {t('SaveNote')}
                              </button>
                            ) : null}
                          </div>
                        )}

                        {summaryNotesForLead &&
                          summaryNotesForLead.length > 0 ? (
                          <LoanNotes
                            leadId={leadIdFromLoanId}
                            noteFromComp={summaryNotesForLead}
                          />
                        ) : (
                          <TitleWrapper title={t('SavedNotes')}>
                            <EmptyContent text={t('NoSavedNotes')} />
                          </TitleWrapper>
                        )}

                        {leadIdFromLoanId ? (
                          <LoanReminders
                            leadId={leadIdFromLoanId}
                            noteFromComp={summaryNotesForLead}
                          />
                        ) : null}
                      </div>
                      {/* <div className={classes.phaseDetailsItem}>
                <h4>Summary notes</h4>
                <textarea className={classes.noteTextArea}  value={userSummaryLoanNote} onChange={(e)=>saveSummaryLeadNote(e)}  />
                <div className={classes.saveNote}>
                  <button className={classes.saveNoteBtn}  onClick={()=>sendLeadNote('LOAN_SUMMARY')}>Save note</button>
                </div>
                {
                  summaryNotesForLead && summaryNotesForLead.length > 0 ?   <LoanNotes  noteFromComp={summaryNotesForLead} /> : <EmptyContent text="No saved notes" />
                }
              
              </div> */}
                    </div>
                  </div>
                </section>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              minHeight: '200px',
              justifyContent: 'center',
              paddingTop: '8rem'
            }}
          >
            <div style={{ flexBasis: '50%' }}>
              <CarNotSelected />
            </div>
            <div style={{ flexBasis: '30%' }}>
              <div>
                <h4>{t('AddNote')}</h4>
                <textarea
                  className={classes.noteTextArea}
                  value={userLoanNote}
                  onChange={(e) => saveLeadNote(e)}
                />

                <div
                  style={{
                    display: 'flex',
                    minHeight: '20px',
                    alignItems: 'center'
                  }}
                >
                  {userIsAgent ? (
                    <div>
                      <Checkbox
                        checked={removeCheck}
                        onChange={(e) => addNoteField()}
                      />
                    </div>
                  ) : null}
                  {userIsAgent ? (
                    <div
                      onClick={(e) => addNoteField()}
                      style={{ cursor: 'pointer' }}
                    >
                      {t('SetReminder')}
                    </div>
                  ) : (
                    <div
                      style={{
                        minHeight: '30px',
                        background: '#f1f3f466',
                        color: '#a1aace',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px',
                        marginTop: '10px',
                        borderRadius: '5px'
                      }}
                    >
                      {t('LogAgentSaveReminder')}
                    </div>
                  )}
                </div>

                {removeCheck && userIsAgent ? (
                  <Reminder
                    loanNote={userLoanNote}
                    userId={userId}
                    id={leadIdFromLoanId}
                    clearText={() => clearTextArea()}
                    saveNote={() => addNoteField()}
                  />
                ) : (
                  <div className={classes.saveNote}>
                    {userIsAgent ? (
                      <button
                        className={classes.saveNoteBtn}
                        onClick={() => sendLeadNote('LOAN_NOTE')}
                      >
                        {t('SaveNote')}
                      </button>
                    ) : null}
                  </div>
                )}

                {summaryNotesForLead && summaryNotesForLead.length > 0 ? (
                  <LoanNotes
                    leadId={leadIdFromLoanId}
                    noteFromComp={summaryNotesForLead}
                  />
                ) : (
                  <TitleWrapper title={t('SavedNotes')}>
                    <EmptyContent text={t('NoSavedNotes')} />
                  </TitleWrapper>
                )}

                {leadIdFromLoanId ? (
                  <LoanReminders
                    leadId={leadIdFromLoanId}
                    noteFromComp={summaryNotesForLead}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </WithSideMenu>
  )
}

interface IPhaseHeaderProps {
  phaseName: string
  active?: boolean
  changeTabProp: (id: string) => void
  idPassed: string
  passMark: boolean
}

const PhaseHeader = ({
  phaseName,
  active,
  idPassed,
  passMark,
  changeTabProp
}: IPhaseHeaderProps) => {
  const classes = useStyles()

  return (
    <div
      onClick={() => changeTabProp(idPassed)}
      className={`${classes.phaseHeader} ${active ? 'active' : ''}`}
    >
      {passMark ? <CheckMarkIconPass /> : <CheckMarkIcon />}

      <p>{phaseName}</p>
    </div>
  )
}

interface IEmptyContentProps {
  text: string
}

const LoanNotes = (props: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const paginationOfActivityForLoanNote: any = useSelector(loanNotePagination)
  const [pageNumber, setPage] = React.useState(1)
  const pageCount: any = Math.ceil(
    paginationOfActivityForLoanNote.total /
    paginationOfActivityForLoanNote.pageSize
  )
  const { id } = useParams<ILoanDetailPathParam>()

  const handleChange = (event: any, value: any) => {
    // console.log(value)
    setPage(value)
    dispatch(getLeadNotes(props.leadId, 'LOAN_NOTE', value))
  }

  const getAuthor = (data: string) => {
    try {
      return JSON.parse(data)
    } catch { }
  }
  return (
    <TitleWrapper title="Saved notes">
      <React.Fragment>
        <div style={{ paddingBottom: '10px', width: '100%' }}>
          {/* <div className={classes.savedNotesBlock}>
     <span>Saved notes</span>
     </div> */}
          {props.noteFromComp.map((note: any) => (
            <div
              key={note.id}
              style={{
                border: '1px solid #E5E5E5',
                marginBottom: '10px',
                marginTop: '13px',
                borderRadius: '5px',
                paddingBottom: '10px'
              }}
            >
              <div className={classes.authorDate}>
                <span className={classes.authorDateChild}>
                  {getAuthor(note.authorId).firstname +
                    ' ' +
                    getAuthor(note.authorId).lastname}
                </span>
                <span className={classes.authorDateChildTwo}>
                  {dayjs(note.createdAt).format('DD/MM/YYYY') +
                    ' ' +
                    new Date(note.createdAt).getHours() +
                    ':' +
                    new Date(note.createdAt).getMinutes()}
                </span>
              </div>
              <span className={classes.noteText}>{note.text}</span>
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '10px'
            }}
          >
            <Pagination
              count={pageCount}
              page={pageNumber}
              onChange={handleChange}
            />
          </div>
        </div>
      </React.Fragment>
    </TitleWrapper>
  )
}

interface IschedulePagination {
  total: any
  pageSize: any
  currentPage: any
}

const LoanReminders = (props: any) => {
  const classes = useStyles()
  const [activityFromCom, setActivityFromComp] = React.useState([] as any)
  const token = useSelector(getUserToken)
  const dispatch = useDispatch()
  const activityArray: any = useSelector(getActivityArray)
  const paginationOfActivityForLoanNote: any = useSelector(loanNotePagination)
  const [pagination, setPagination] = React.useState<IschedulePagination>()
  const [pageNumber, setPage] = React.useState(1)
  const pageCount: any = Math.ceil(pagination?.total / pagination?.pageSize)
  const { id } = useParams<ILoanDetailPathParam>()

  const fetchLoanReminders = useCallback(
    async (query) => {
      let response
      try {
        response = await api.getActivityType(query, token)

        setActivityFromComp(response?.schedule)
        setPagination(response.pagination)
        // console.log(response, 'response from schedule')
      } catch (e) { }

      return response?.schedule
    },
    [id, token]
  )

  React.useEffect(() => {
    let query = {
      activity_type: 'LOAN_REMINDER',
      completed: true,
      lead_id: props.leadId,
      page_size: 3,
      current_page: pageNumber
    }
    fetchLoanReminders(query)
  }, [])
  React.useEffect(() => {
    let query = {
      activity_type: 'LOAN_REMINDER',
      completed: true,
      lead_id: props.leadId,
      page_size: 3,
      current_page: pageNumber
    }

    setActivityFromComp(fetchLoanReminders(query))
  }, [pageNumber])

  const handleChange = (event: any, value: any) => {
    setPage(value)
  }

  const getAuthor = (data: string) => {
    try {
      return JSON.parse(data)
    } catch { }
  }
  return (
    <TitleWrapper title="Completed reminders">
      <div style={{ width: '100%' }}>
        {/* <div className={classes.savedNotesBlock}>

     </div> */}
        {/* <div style={{marginTop:'10px'}}>

     </div> */}

        {activityFromCom.length > 0 ? (
          <div>
            {activityFromCom?.map((item: any) => (
              <div
                style={{
                  border: '1px solid #E5E5E5',
                  marginBottom: '10px',
                  marginTop: '13px',
                  borderRadius: '5px',
                  paddingBottom: '10px'
                }}
                key={item.id}
              >
                {item.note?.reverse().map((singleItem: any) => (
                  <div key={singleItem.id}>
                    <div className={classes.authorDate}>
                      <span className={classes.authorDateChild}>
                        {getAuthor(singleItem.authorId).firstname +
                          ' ' +
                          getAuthor(singleItem.authorId).lastname}
                      </span>
                      <span className={classes.authorDateChildTwo}>
                        {dayjs(singleItem.createdAt).format('DD/MM/YYYY') +
                          ' ' +
                          new Date(singleItem.createdAt).getHours() +
                          ':' +
                          new Date(singleItem.createdAt).getSeconds()}
                      </span>
                    </div>
                    <span className={classes.noteText}>{singleItem.text}</span>
                  </div>
                ))}
              </div>
            ))}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px',
                paddingTop: '10px'
              }}
            >
              <Pagination
                count={pageCount}
                page={pageNumber}
                onChange={handleChange}
              />
            </div>
          </div>
        ) : (
          <EmptyContent text="No reminder notes" />
        )}
      </div>
    </TitleWrapper>
  )
}
const EmptyContent = ({ text }: IEmptyContentProps) => {
  const classes = useStyles()

  const { t } = useTranslation()

  return (
    <div
      className={classes.emptyContent}
      style={{ minWidth: '100%', marginTop: '13px', marginBottom: '14px' }}
    >
      <ClipboardIcon />
      {t(text)}
    </div>
  )
}
const CarNotSelected = () => {
  const useStyles = makeStyles((theme) => ({
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }))
  const [loanType, setLoanType] = React.useState('')

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const classes2 = useStyles()
  const handleClose = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    let loanTypeFromStorage = localStorage.getItem('loanType') as string
    setLoanType(loanTypeFromStorage)
  }, [])

  const id = open ? 'simple-popover' : undefined

  const { t } = useTranslation()
  return (
    <div
      style={{
        minWidth: '100%',
        marginTop: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '14px'
      }}
    >
      <div
        style={{
          minWidth: '400px',
          minHeight: '300px',
          border: '1.5px solid #e9e9e9',
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
          boxShadow:
            '0 12px 11px rgb(0 0 0 / 4%), 0 100px 80px rgb(0 0 0 / 7%)',
          justifyContent: 'center',
          padding: '10px'
        }}
      >
        <EmptyContent text="No data" />
        <p>{t('CarNotSelected')}</p>

        {loanType === 'REGULAR' ? (
          <Link
            to="/loans"
          // onClick={history.goBack}
          >
            <button style={{ cursor: 'pointer' }}>{t('Goback')}</button>
          </Link>
        ) : null}
        {loanType === 'DSA' ? (
          <Link
            to="/dsa"
          // onClick={history.goBack}
          >
            <button style={{ cursor: 'pointer' }}>{t('Goback')}</button>
          </Link>
        ) : null}
        {loanType === 'FRANCHISE' ? (
          <Link
            to="/franchise"
          // onClick={history.goBack}
          >
            <button style={{ cursor: 'pointer' }}>{t('Goback')}</button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default LoanDetails
