import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addNewMessageAlert,
  fetchMessageStats,
  INewMessageAlert,
  updateWebSocketConnection
} from './store/conversations/actions'
import { showSnackbar } from './store/snackbar/actions'
import io from 'socket.io-client'
import Routes from './routes'
import Snackbar from './components/Snackbar'
import { getUserId, isAdmin, isManager } from './store/auth/selectors'
import { newMessageNotification } from './store/notifications/actions'
import { getCrmNotifications } from './store/leads/actions'
import useSound from 'use-sound'
import soundPlay from './sounds/noty.mp3'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const WS_URL = process.env.REACT_APP_CRM_API_URL as string

const App = () => {
  const isManagerUser = useSelector(isManager)
  const isAdminUser = useSelector(isAdmin)
  const [playSound] = useSound(soundPlay)

  const dispatch = useDispatch()
  const socketRef = useRef<SocketIOClient.Socket>()
  const userId = useSelector(getUserId)

  useEffect(() => {
    socketRef.current = io(WS_URL, {
      transports: ['websocket']
    })

    socketRef.current.emit('CRM_AGENTS')

    socketRef.current.on('connect', () => {
      dispatch(updateWebSocketConnection(true))
    })

    socketRef.current.on('disconnect', () => {
      dispatch(updateWebSocketConnection(false))
    })

    socketRef.current.on('INCOMING_MESSAGE', (data: INewMessageAlert) => {
      if (userId === data.agentId || isManagerUser || isAdminUser) {
        dispatch(showSnackbar('New Message', 'success'))
        dispatch(addNewMessageAlert(data))
        dispatch(newMessageNotification(data))
        dispatch(fetchMessageStats())
      }
    })

    socketRef.current.on('CRM_NOTIFICATION', (data: any) => {
      if (userId === data.agentId) {
        let query = {
          read: false,
          agentId: data.agentId
        }
        playSound()
        let dataFromSocket = data
        dataFromSocket.content = JSON.parse(data.content)
        dispatch(
          showSnackbar(
            `Reminder: you have a pending ${data.type.replace(
              /_/g,
              ' '
            )} with  ${
              dataFromSocket.content?.lead?.firstName +
              ' ' +
              dataFromSocket.content?.lead?.lastName
            }`,
            'info'
          )
        )
        dispatch(getCrmNotifications(query))
      }
    })

    return () => {
      socketRef.current?.disconnect()
    }
  }, [dispatch, userId])

  return (
    <div>
      <Routes />
      <Snackbar />
    </div>
  )
}

export default App
