import { Box, Button, Divider, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import calendar from '../../../../images/calendar.svg'
import box from '../../../../images/box.svg'
import mail from '../../../../images/mail.svg'
import plus from '../../../../images/plus.svg'
import ActionButton from './ActionButton'
import { useActionStyles } from './styles'
import { commOptions, eventOptions, scheduleOptions } from './lead.utils'
import ScheduleForm from './ScheduleForm'
import { getEvents } from '../../../../store/events/selectors'
import { getUserId } from '../../../../store/auth/selectors'
import { setEvent } from '../../../../store/events/actions'
import {
  carAppointmentStatus,
  leadFromStore,
  leadNotes
} from '../../../../store/leads/selectors'
import SaveCarAppointment from './SaveCarAppointment'
import {
  cancelCarAppointmentData,
  deactivateUserIsFulfilmentAgent,
  addLeadNote,
  getLeadNotes
} from '../../../../store/leads/actions'
import { useTranslation } from 'react-i18next'
import { PulseDiv } from '../ServiceInterest/styles'
import Select from '../../../../components/CustomizedSelect/Select'
import { message } from 'antd'
interface IProps { }

const ActionPane: React.FC<IProps> = (props) => {
  const classes = useActionStyles()

  const [userNote, setUserNote] = React.useState(false)
  const [userNoteText, setUserNoteText] = React.useState('')
  const [userComment, setUserComment] = useState('')

  const authorId = useSelector(getUserId)
  const lead = useSelector(leadFromStore)
  const dispatch = useDispatch()
  const carAppointmentStatusFromStore: any = useSelector(carAppointmentStatus)

  const value = useSelector(getEvents)

  const handleClick = (value: string, event?: boolean) => {
    let data = value
    if (event) data = `Log - ${value}`
    dispatch(setEvent(data))
    dispatch(cancelCarAppointmentData())
    dispatch(deactivateUserIsFulfilmentAgent())
  }
  const saveLeadNote = (event?: any) => {
    setUserNoteText(event.target.value)
  }
  const saveLeadComment = (event?: any) => {
    setUserComment(event.target.value)
  }
  const sendLeadNote = () => {
    if(userNoteText === "") {
      message.error("Select a note value to proceed");
      return;
    }
    let data = {
      author_id: authorId,
      text: userNoteText,
      lead_id: lead.id,
      comment: userComment
    }
    setUserNote(false)
    dispatch(addLeadNote(data))
    setTimeout(() => {
      dispatch(getLeadNotes(lead.id, 'REGULAR', 1))
    }, 2000)
  }

  const openNote = () => {
    setUserNote(true)
  }
  const closeNote = () => {
    setUserNote(false)
  }

  const { t } = useTranslation()
  return (
    <Grid item xs={12} className={classes.container}>
      <Grid item xs={12} className={classes.containerInner}>
        <Grid item xs={12} className={classes.btnWrapper}>
          <ActionButton
            options={{ ...scheduleOptions, handleClick }}
            icon={calendar}
            title={t('scheduleActivity')}
          />
          <ActionButton
            options={{ ...eventOptions, handleClick }}
            icon={box}
            title={t('logEvent')}
          />
          <ActionButton
            options={{ ...commOptions, handleClick }}
            icon={mail}
            title={t('connectLead')}
          />
        </Grid>

        <Grid>
          {!userNote ? (
            <Button
              variant="outlined"
              className={classes.noteBtn}
              style={{ marginTop: '30px' }}
              fullWidth
              onClick={() => openNote()}
              startIcon={<img src={plus} alt="add" />}
            >
              {t('addQuickNote')}
            </Button>
          ) : null}
        </Grid>
        {userNote && !value && !carAppointmentStatusFromStore ? (
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Box style={{
              marginBottom: '8px'
            }}>
              <Select
                value={userNoteText}
                name=""
                label={t('Add a Note')}
                options={[
                  { label: t('Unanswered'), value: 'Unanswered' },
                  { label: t('Unreachable'), value: 'Unreachable' },
                  { label: t('Busy'), value: 'Busy' },
                  { label: t('Scheduled to sell'), value: 'Scheduled to sell' },
                  { label: t('Scheduled to buy'), value: 'Scheduled to buy' },
                  { label: t('Scheduled to fix'), value: 'Scheduled to fix' },
                  { label: t('Incorrect contact: To email'), value: 'Incorrect contact: To email' },
                  { label: 'Loan Lead: CP', value: 'Loan lead: CP' },
                  { label: 'Car Unavailable: Customer not interested', value: 'Car Unavailable: Customer not interested' },
                  { label: t('Car Unavailable: Customer requested for a replacement'), value: 'Car Unavailable: Customer requested for a replacement' },
                  { label: t('Client not contacted'), value: 'Client not contacted' },
                  { label: t('Successful Conversion'), value: 'Successfull Conversion' },
                  { label: t('Completed Profile'), value: 'Completed Profile' },
                  { label: t('Test'), value: 'Test' },
                  { label: t('Potential Client'), value: 'Potential Client' },
                  { label: t('Window Shopping'), value: 'Window Shopping' },
                  { label: t('Unavailable client'), value: 'Unavailable client' },
                  { label: t('Got a Unit'), value: 'Got a Unit' },
                  { label: t('Contacted via Whatsapp: Scheduled to buy'), value: 'Contacted via Whatsapp: Scheduled to buy' },
                  { label: t('Contacted by LM'), value: 'Contacted by LM' },
                  { label: t('Other'), value: 'Other' }
                ]}
                onSelect={(e: any) => { setUserNoteText(e.target.value) }}
              />
            </Box>

            <input
              name="first_seen_start_date"
              placeholder={t('Add a Comment')}
              type="text"
              style={{
                height: '60px',
                borderRadius: '3px',
                minWidth: '100%',
                padding: '10px',
                marginBottom: '10px',
                border: '1px solid #E6EAF0'
              }}
              onChange={(e) => saveLeadComment(e)}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                className={classes.noteBtn}
                onClick={() => closeNote()}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '10px' }}
                onClick={() => sendLeadNote()}
              >
                {t('addNote')}
              </Button>
            </div>
          </Grid>
        ) : null}
      </Grid>

      {leadNotes}
      {!!value && !carAppointmentStatusFromStore && <ScheduleForm />}

      {carAppointmentStatusFromStore ? (
        <PulseDiv>
          <SaveCarAppointment />
        </PulseDiv>
      ) : null}
    </Grid>
  )
}

export default ActionPane
