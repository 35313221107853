import React from 'react'
import Pagination from '@material-ui/lab/Pagination'
import WithSideMenu from '../../components/WithSideMenu'
import EmailIcon from './svg/Email'
import LastSeenIcon from './svg/LastSeenIcon'
import PersonIcon from './svg/PersonIcon'
import PhoneIcon from './svg/PhoneIcon'
import ServiceIcon from './svg/ServiceIcon'
import SourceIcon from './svg/SourceIcon'
import StatusIcon from './svg/StatusIcon'
import ValueIcon from './svg/ValueIcon'
import Checkbox from '../../components/Checkbox'
import Popover from '@material-ui/core/Popover'
import Select from '../../components/CustomizedSelect'
import TextInput from '../../components/TextField'
import Loader from '../../components/Loader'
import CreateLead from './CreateLead'
import TaskDropDown from '../../components/taskDropdown'
import ReminderDropDown from '../../components/reminderDropdown'
import { Link } from 'react-router-dom'
import CheckboxMaterialUi from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import { Props, State } from './types'
import ReactSelect from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import PlusIcon from '../../images/Plus Icon.svg'
import api from '../../utils/api'
import { Form } from 'react-bootstrap'

import {
  LeadsContainer,
  LeadsMiddleSection,
  LeadsMiddleSectionText,
  LeadsPageTitile,
  LeadsTopSection,
  LeadsMiddleSectionButtons,
  SearchContainer,
  SearchContentInactive,
  SearchIcon,
  Divider,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  HeaderIcon,
  HeaderText,
  HeaderIconText,
  CheckBoxWrap,
  CheckBoxWrapTwo,
  SearchDropDown,
  SearchLeftFilters,
  FiltersTitle,
  FiltersDivider,
  FilterTitle,
  FilterTag,
  SearchRight,
  ButtonsContainer,
  SubmitButton,
  ClearButton,
  ShowNameFilters,
  CreateLeadBtn,
  SelectFilterOperatorText,
  FilterOperatorContainer,
  FilterOperatorTitle,
  PaginationContainer,
  StyledSelect
} from './styles'
import { statusOptions } from './lead.util'
import { AgentName } from './LeadDetails/styles'
import Button from '@material-ui/core/Button'
import {
  isManager,
  isAdmin,
  getUserIdIfAgent
} from '../../store/auth/selectors'
import { bankDealerSearch } from '../../store/leads/selectors'
import { getCountryCode } from '../../utils/country.util'
import { withTranslation } from 'react-i18next'
import { NewCreateLeadBtn } from '../Activities/styles'

const tableHeader = [
  {
    icon: <PersonIcon />,
    text: 'NAME',
    longCell: true
  },
  {
    icon: <EmailIcon />,
    text: 'EMAIL',
    longCell: true,
    shortCell: false
  },
  {
    icon: <PhoneIcon />,
    text: 'PHONE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <ServiceIcon />,
    text: 'SERVICE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <StatusIcon />,
    text: 'STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <SourceIcon />,
    text: 'SOURCE',
    longCell: false,
    shortCell: true
  },
  {
    icon: <ValueIcon />,
    text: 'VALUE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'LAST SEEN',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'FIRST SEEN',
    longCell: false,
    shortCell: false
  },

  {
    icon: <PersonIcon />,
    text: 'ASSIGNED TO',
    longCell: false,
    shortCell: false
  }
]

const agentHeader = [
  {
    icon: <PersonIcon />,
    text: 'NAME',
    longCell: true
  },
  {
    icon: <EmailIcon />,
    text: 'EMAIL',
    longCell: true,
    shortCell: false
  },
  {
    icon: <PhoneIcon />,
    text: 'PHONE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <ServiceIcon />,
    text: 'SERVICE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <StatusIcon />,
    text: 'STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <SourceIcon />,
    text: 'SOURCE',
    longCell: false,
    shortCell: true
  },
  {
    icon: <ValueIcon />,
    text: 'VALUE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'LAST SEEN',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'FIRST SEEN',
    longCell: false,
    shortCell: false
  }
]

const inputProps = {
  width: '100%',
  height: '40px',
  color: '#000',
  labelColor: '#8F99A6',
  borderRadius: 'none',
  transparent: true,
  borderColor: '#E6EAF0',
  labelMarginBottom: '4px',
  labelMarginTop: '16px'
}

let t1: any

export const getTableHeader = (
  isAdminUser: boolean,
  isManagerUser: boolean
) => {
  let headerToBeRendered =
    isAdminUser || isManagerUser ? tableHeader : agentHeader

  return (
    <TableHeader>
      {/* <CheckBoxWrap>
      </CheckBoxWrap> */}
      {/* <CheckBoxWrap>
        <Checkbox checked={true} handleChange={() => {}} />
      </CheckBoxWrap> */}
      {headerToBeRendered.map((header, index) => {
        return (
          <HeaderIconText
            long={header.longCell}
            short={header.shortCell}
            key={index + header.text}
          >
            <HeaderIcon>{header.icon}</HeaderIcon>
            <HeaderText>{t1(header.text)}</HeaderText>
          </HeaderIconText>
        )
      })}
    </TableHeader>
  )
}

const initialNewLead = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  service: '',
  status: '',
  value: '',
  lead_type: '',
  country: '',
  source: '',
  assign_lead_to_self: undefined
}

class Leads extends React.Component<Props, State> {
  state = this.props.prevState || {
    anchorEl: null,
    anchorElName: null,
    queries: {
      page_size: 10,
      current_page: 1,
      assigned_agent_id: this.props.agentId,
      country: this.props.selectedCountry,
      status: undefined,
      phone: undefined,
      email: undefined,
      source: undefined,
      state: undefined,
      city: undefined,
      agent: undefined,
      minValue: undefined,
      maxValue: undefined,
      name: undefined,
      service: undefined,
      userAgent: undefined,
      nameFilter: undefined
    },
    activeFilters: {},
    showActiveFilters: false,
    createLeadModalOpen: false,
    checked: false,
    _states: [],
    _cities: [],
    _countryId: {},
    newLead: {
      ...initialNewLead,
      country: this.props.selectedCountry
    }
  }

  componentDidMount = () => {
    localStorage.setItem('loanType', 'REGULAR')
    const { getAllLeads, getAgents } = this.props
    const { queries } = this.state
    let queryinStore: any
    let activeFilterInStore: any
    queryinStore = localStorage.getItem('queriesInStorage')
    activeFilterInStore = localStorage.getItem('setActiveFilter')
    queryinStore = JSON.parse(queryinStore)
    activeFilterInStore = JSON.parse(activeFilterInStore)

    if (
      (queryinStore && activeFilterInStore) ||
      (queryinStore && queryinStore.current_page !== 1)
    ) {
      this.setState({
        showActiveFilters: true,
        activeFilters: activeFilterInStore,
        anchorEl: null,
        queries: queryinStore
      })

      queryinStore.assigned_agent_id = this.props.agentId
      // queryinStore.current_page = 1

      // console.log(queryinStore, 'query')
      getAllLeads(queryinStore)
    } else {
      getAllLeads(queries)
    }
    getAgents()

    const getCountryId = async () => {
      try {
        const response = await api.fetchCountryId(
          this.props.token,
          this.props.selectedCountry
        )
        this.setState(
          {
            _countryId: response.id
          },
          () => {
            const getStates = async () => {
              try {
                const response = await api.fetchStates(
                  this.props.token,
                  this.state._countryId
                  // this.props.countryId
                )
                this.setState({
                  _states: response.states
                })
              } catch (e) {
                console.error(e)
              }
            }
            getStates()
          }
        )
      } catch (e) {
        console.error(e)
      }
    }
    getCountryId()
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let target = event.currentTarget as HTMLInputElement
    this.setState({
      anchorEl: target
    })
  }

  handleNameInputClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    let target = event.currentTarget as HTMLInputElement
    this.setState({
      anchorElName: target
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleNamePopClose = () => {
    this.setState({
      anchorElName: null
    })
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { activeFilters, queries } = this.state
    const key = event.currentTarget.name
    const value = event.target.value

    this.setState({
      queries: { ...queries, [key]: value },
      activeFilters: { ...activeFilters, [key]: value }
    })
  }

  handleSelectState = async (e: any) => {
    const { activeFilters, queries } = this.state
    let index = e.nativeEvent.target.selectedIndex

    this.setState({
      queries: {
        ...queries,
        state: e.nativeEvent.target[index].text
      },
      activeFilters: {
        ...activeFilters,
        state: e.nativeEvent.target[index].text
      }
    })

    try {
      const response = await api.fetchCity(this.props.token, e.target.value)
      this.setState({
        _cities: response.cities
      })
    } catch (e) {
      console.error(e)
    }
  }

  handleSelectCity = (e: any) => {
    const { activeFilters, queries } = this.state
    let index = e.nativeEvent.target.selectedIndex

    this.setState({
      queries: {
        ...queries,
        city: e.nativeEvent.target[index].text
      },
      activeFilters: {
        ...activeFilters,
        city: e.nativeEvent.target[index].text
      }
    })
  }

  handleSelect = (event: any) => {
    const { activeFilters, queries } = this.state
    const target = event.target as HTMLInputElement
    const key = target.name
    const value = target.value

    this.setState({
      queries: { ...queries, [key]: value },
      activeFilters: {
        ...activeFilters,
        [key]: event.target.options[event.target.selectedIndex].text
      }
    })
  }

  filterLeads = () => {
    const { queries, activeFilters } = this.state
    const { getAllLeads, savePrevState } = this.props
    this.setState(
      {
        queries: { ...queries, ...{ current_page: 1 } },
        showActiveFilters: true,
        anchorEl: null
      },
      () => savePrevState(this.state)
    )

    // console.log(activeFilters, 'active')
    // console.log(queries, 'queries')
    localStorage.setItem('queriesInStorage', JSON.stringify(queries))
    localStorage.setItem('setActiveFilter', JSON.stringify(activeFilters))

    getAllLeads(queries)
  }

  handleRemoveFilterTag = (filter: string) => {
    const { activeFilters, queries } = this.state

    this.setState(
      {
        queries: { ...queries, ...{ [filter]: undefined, current_page: 1 } },
        activeFilters: { ...activeFilters, [filter]: undefined }
      },
      () => this.filterLeads()
    )
  }

  handleRemoveAllFilters = () => {
    this.setState(
      {
        queries: {
          page_size: 10,
          current_page: 1,
          assigned_agent_id: this.props.agentId,
          country: this.props.selectedCountry,
          status: undefined,
          phone: undefined,
          email: undefined,
          source: undefined,
          state: undefined,
          city: undefined,
          minValue: undefined,
          maxValue: undefined,
          name: undefined,
          service: undefined,
          nameFilter: undefined,
          utm_medium: undefined,
          utm_campaign: undefined,
          utm_content: undefined,
          utm_source: undefined,
          first_seen_start_date: undefined,
          first_seen_end_date: undefined,
          last_seen_start_date: undefined,
          last_seen_end_date: undefined
        },
        activeFilters: {},
        showActiveFilters: false
      },
      () => this.filterLeads()
    )
  }

  handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.checked ? event.target.name : ''
    const { queries } = this.state
    this.setState({
      queries: { ...queries, nameFilter: name }
    })
  }

  getTableRows = () => {
    const handleCheckBox = (event: any, id: string) => {
      event.stopPropagation()

      //  console.log(this.props)
      let agentsSaved = [...agentIds]
      if (event.target.checked) {
        agentsSaved.push(...agentIds, id)
      } else {
        agentsSaved = agentsSaved.filter((data: string) => {
          return data !== id
        })
      }
      function onlyUnique(value: any, index: any, self: any) {
        return self.indexOf(value) === index
      }
      let uniqueValues

      uniqueValues = agentsSaved.filter(onlyUnique)

      saveMultiAgents(uniqueValues)
      //  console.log(uniqueValues)
    }
    const gotoLeads = (e: any, id: string) => {
      e.preventDefault()
      window.location.href = `/leads/${id}`
    }

    const {
      leads,
      currency,
      isAdminUser,
      isManagerUser,
      agentIds,
      saveMultiAgents
    } = this.props

    return leads.map((row, index) => {
      const {
        id,
        name,
        email,
        phone,
        service,
        status,
        source,
        agentName,
        value,
        lastSeen,
        firstSeen,
        multipleAgents
      } = row
      return (
        //  <Link to={`/leads/${id}`} key={id}>
        <TableRow key={id}>
          {isAdminUser || isManagerUser ? (
            <CheckboxMaterialUi onChange={(e) => handleCheckBox(e, id)} />
          ) : null}

          <TableCell
            long
            capitalize
            style={{
              cursor: 'pointer',
              marginRight: isAdminUser || isManagerUser ? '0px' : '',
              paddingLeft: '20px'
            }}
            onClick={(e) => gotoLeads(e, id)}
          >
            {name}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={(e) => gotoLeads(e, id)}
            long
          >
            {email?.toLowerCase()}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={(e) => gotoLeads(e, id)}
          >
            {phone}
          </TableCell>
          <TableCell
            onClick={(e) => gotoLeads(e, id)}
            style={{ cursor: 'pointer' }}
          >
            {service}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            capitalize
            short
            onClick={(e) => gotoLeads(e, id)}
          >
            {status}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            short
            onClick={(e) => gotoLeads(e, id)}
          >
            {source}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={(e) => gotoLeads(e, id)}
          >
            {currency} {value.toLocaleString()}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={(e) => gotoLeads(e, id)}
          >
            {lastSeen}
          </TableCell>
          <TableCell
            style={{ cursor: 'pointer' }}
            onClick={(e) => gotoLeads(e, id)}
          >
            {firstSeen}
          </TableCell>

          {isAdminUser || isManagerUser ? (
            <TableCell>{multipleAgents}</TableCell>
          ) : null}
        </TableRow>
        // </Link>
      )
    })
  }

  getActiveFilters = () => {
    const { activeFilters } = this.state
    if (!activeFilters) {
      return
    }

    const values = activeFilters && Object.values(activeFilters)
    return Object.keys(activeFilters).map((key, index) => {
      const filter = key === 'assigned_agent_id' ? 'AGENT' : key.toUpperCase()

      return (
        values[index] !== undefined && (
          <FilterTag
            label={`${filter}: ${values[index]}`}
            onDelete={() => this.handleRemoveFilterTag(key)}
            color="primary"
            key={key}
          />
        )
      )
    })
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const { queries } = this.state
    const { getAllLeads, savePrevState } = this.props
    this.setState(
      {
        queries: { ...queries, current_page: page }
      },
      () => {
        getAllLeads(this.state.queries)
        savePrevState(this.state)
      }
    )
    let storeQuery = { ...queries, current_page: page }
    localStorage.setItem('queriesInStorage', JSON.stringify(storeQuery))
  }

  handleCreateLeadModalOpen = () => {
    const { createLeadModalOpen } = this.state

    this.setState({
      createLeadModalOpen: !createLeadModalOpen
    })
  }

  handleCreateLeadInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(this.props.bankDealerString, 'event name')
    const { newLead } = this.state
    const key = event.currentTarget.name
    let value: string | number = event.target.value
    if (key === 'value') {
      value = parseInt(value)
    }
    if (key === 'Bank' || key === 'Dealer') {
      value = value + ' ' + this.props.bankDealerString
    }

    this.setState({
      newLead: { ...newLead, [key]: value }
    })
  }

  handleCheck = () => {
    const { checked } = this.state
    if (checked == false) {
      this.setState({
        checked: true
      })
    } else {
      this.setState({
        checked: false
      })
    }
  }

  handleCreateLead = () => {
    const { newLead, queries } = this.state
    const { createLead, selectedCountry } = this.props

    if (newLead.source === 'Bank' || newLead.source === 'Dealer') {
      newLead.source = newLead.source + '-' + this.props.bankDealerString
    }

    const phoneNumberWithCode = getCountryCode(selectedCountry) + newLead.phone
    const leadInfo = {
      ...newLead,
      phone: phoneNumberWithCode,
      assign_lead_to_self: this.state.checked
    }

    createLead(leadInfo, queries)
    this.handleCreateLeadModalOpen()
    this.setState({
      newLead: {
        ...initialNewLead,
        country: this.props.selectedCountry
      }
    })
  }

  render() {
    //@ts-ignore
    const { t } = this.props
    t1 = t

    const {
      total,
      isFetching,
      agents,
      isAdminUser,
      isManagerUser,
      agentIds,
      saveAssignedAgent,
      sendAssignedLeadsToAgent,
      emptyAssignedList
    } = this.props

    const numOfPages = Math.ceil(total / 10)
    const {
      anchorEl,
      anchorElName,
      showActiveFilters,
      createLeadModalOpen,
      newLead,
      queries: {
        source,
        state,
        city,
        service,
        minValue,
        maxValue,
        email,
        phone,
        status,
        name,
        nameFilter,
        current_page,
        assigned_agent_id,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_source,
        first_seen_start_date,
        first_seen_end_date,
        last_seen_start_date,
        last_seen_end_date
      }
    } = this.state
    const open = Boolean(anchorEl)
    const namePopoverOpen = Boolean(anchorElName)
    const id = open ? 'simple-popover' : undefined
    const namePopId = namePopoverOpen ? 'simple-name-popover' : undefined

    const saveAssignedAgentInComp = (e: any) => {
      // console.log(e.value)
      saveAssignedAgent(e.value)
    }
    const sendLeadsList = () => {
      sendAssignedLeadsToAgent()
      emptyAssignedList()
      setTimeout(() => {
        this.props.getAllLeads(this.state.queries)
      }, 500)
    }

    let agentArray: any = []
    let users = agents.filter(function (data: any) {
      agentArray.push({ label: data.name, value: data.id })

      return data
    })

    return (
      <WithSideMenu>
        <LeadsContainer>
          <LeadsTopSection>
            <LeadsPageTitile>{t('LEADS')}</LeadsPageTitile>
            <SearchContainer onClick={this.handleClick}>
              {showActiveFilters ? (
                this.getActiveFilters()
              ) : (
                <>
                  <SearchIcon />
                  <SearchContentInactive>
                    {t('searchLeads')}
                  </SearchContentInactive>
                </>
              )}
            </SearchContainer>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <SearchDropDown>
                <SearchLeftFilters>
                  <FiltersTitle>{t('quickFilters')}</FiltersTitle>
                  <FiltersDivider />
                  <FilterTitle>{t('leadsProgress')}</FilterTitle>
                  <FiltersDivider />
                  <FilterTitle>{t('closedLeads')}</FilterTitle>
                  <FiltersDivider />
                  <FilterTitle>{t('repeatLeads')}</FilterTitle>
                  <FiltersDivider />
                </SearchLeftFilters>
                <SearchRight>
                  <TextInput
                    name="name"
                    onClick={this.handleNameInputClick}
                    value={name}
                    label={`${t('name')}${
                      nameFilter ? ` - ${nameFilter}` : ''
                    }`}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <Popover
                    id={namePopId}
                    open={namePopoverOpen}
                    anchorEl={anchorElName}
                    onClose={this.handleNamePopClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                  >
                    <ShowNameFilters>
                      <SelectFilterOperatorText>
                        {t('selectFilterOperator')}
                      </SelectFilterOperatorText>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="is"
                          checked={nameFilter === 'is'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>{t('is')}</FilterOperatorTitle>
                      </FilterOperatorContainer>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="isNot"
                          checked={nameFilter === 'isNot'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>{t('isNot')}</FilterOperatorTitle>
                      </FilterOperatorContainer>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="startsWith"
                          checked={nameFilter === 'startsWith'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>
                          {t('startWith')}
                        </FilterOperatorTitle>
                      </FilterOperatorContainer>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="endsWith"
                          checked={nameFilter === 'endsWith'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>
                          {t('endsWith')}
                        </FilterOperatorTitle>
                      </FilterOperatorContainer>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="contains"
                          checked={nameFilter === 'contains'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>
                          {t('contain')}
                        </FilterOperatorTitle>
                      </FilterOperatorContainer>
                      <FilterOperatorContainer>
                        <Checkbox
                          name="notContain"
                          checked={nameFilter === 'notContain'}
                          handleChange={this.handleCheckBoxChange}
                        />
                        <FilterOperatorTitle>
                          {t('doesNotContain')}
                        </FilterOperatorTitle>
                      </FilterOperatorContainer>
                    </ShowNameFilters>
                  </Popover>
                  <TextInput
                    name="email"
                    value={email}
                    label={t('email')}
                    type="email"
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />

                  <TextInput
                    name="phone"
                    value={phone}
                    label={t('phone')}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <TextInput
                    name="utm_medium"
                    value={utm_medium}
                    label={t('UTMmedium')}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <TextInput
                    name="utm_campaign"
                    value={utm_campaign}
                    label={t('UTMcampaign')}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <TextInput
                    name="utm_content"
                    value={utm_content}
                    label={t('UTMcontent')}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <TextInput
                    name="utm_source"
                    value={utm_source}
                    label={t('UTMsource')}
                    onInputChange={this.handleInputChange}
                    {...inputProps}
                  />
                  <Select
                    value={service || ''}
                    name="service"
                    label={t('service')}
                    options={[
                      { label: t('buyCar'), value: 'BUY_CAR' },
                      { label: t('sellCar'), value: 'SELL_CAR' },
                      { label: t('fixCar'), value: 'FIX_CAR' },
                      { label: t('testDriveCar'), value: 'TEST_DRIVE_CAR' },
                      { label: t('buyTruck'), value: 'BUY_TRUCK' },
                      { label: t('sellTruck'), value: 'SELL_TRUCK' },
                      { label: t('fixTruck'), value: 'FIX_TRUCK' },
                      { label: t('testDriveTruck'), value: 'TEST_DRIVE_TRUCK' }
                    ]}
                    onSelect={this.handleSelect}
                  />

                  {isAdminUser || isManagerUser ? (
                    <Select
                      value={assigned_agent_id || ''}
                      name="assigned_agent_id"
                      label={t('agent')}
                      options={agentArray}
                      onSelect={this.handleSelect}
                    />
                  ) : null}

                  <Select
                    value={status || ''}
                    name="status"
                    label={t('status')}
                    options={statusOptions}
                    onSelect={this.handleSelect}
                  />

                  <Select
                    value={source || ''}
                    name="source"
                    label={t('source')}
                    options={[
                      { label: t('email'), value: 'Email' },
                      { label: t('whatsapp'), value: 'Whatsapp' },
                      { label: t('inboundCall'), value: 'Inbound Call' },
                      { label: t('twitter'), value: 'Twitter' },
                      { label: t('facebook'), value: 'Facebook' },
                      { label: 'Crm', value: 'Crm' },
                      { label: t('website'), value: 'Website' },
                      { label: t('bank'), value: 'Bank' },
                      { label: t('dealer'), value: 'Dealer' }
                    ]}
                    onSelect={this.handleSelect}
                  />

                  <StyledSelect>
                    <Form.Group>
                      <Form.Label>{t('State')}</Form.Label>
                      <Form.Select onChange={this.handleSelectState}>
                        <option></option>
                        {this.state?._states?.map((el: any, index: any) => (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </StyledSelect>

                  <StyledSelect>
                    <Form.Group>
                      <Form.Label>{t('City')}</Form.Label>
                      <Form.Select onChange={this.handleSelectCity}>
                        <option></option>
                        {this.state?._cities?.map((el: any, index: any) => (
                          <option key={index} value={el.id}>
                            {el.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </StyledSelect>

                  <div style={{ display: 'flex' }}>
                    <TextInput
                      name="minValue"
                      value={minValue || ''}
                      label={t('minValue')}
                      onInputChange={this.handleInputChange}
                      {...inputProps}
                    />
                    <span style={{ marginRight: '10px' }}></span>
                    <TextInput
                      name="maxValue"
                      value={maxValue || ''}
                      label={t('maxValue')}
                      onInputChange={this.handleInputChange}
                      {...inputProps}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexBasis: '50%', marginTop: '16px' }}>
                      <span style={{ color: '#8F99A6' }}>
                        {' '}
                        {t('firstSeenStartDate')}{' '}
                      </span>
                      <input
                        name="first_seen_start_date"
                        value={first_seen_start_date || ''}
                        type="date"
                        style={{
                          height: '40px',
                          borderRadius: '3px',
                          minWidth: '100%',
                          padding: '10px',
                          border: '1px solid #E6EAF0'
                        }}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <span style={{ marginRight: '10px' }}></span>
                    <div style={{ flexBasis: '50%', marginTop: '16px' }}>
                      <span style={{ color: '#8F99A6' }}>
                        {' '}
                        {t('firstSeenEndDate')}{' '}
                      </span>
                      <input
                        name="first_seen_end_date"
                        value={first_seen_end_date || ''}
                        type="date"
                        style={{
                          height: '40px',
                          borderRadius: '3px',
                          minWidth: '100%',
                          padding: '10px',
                          border: '1px solid #E6EAF0'
                        }}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flexBasis: '50%', marginTop: '16px' }}>
                      <span style={{ color: '#8F99A6' }}>
                        {' '}
                        {t('lastSeenStartDate')}{' '}
                      </span>
                      <input
                        name="last_seen_start_date"
                        value={last_seen_start_date || ''}
                        type="date"
                        style={{
                          height: '40px',
                          borderRadius: '3px',
                          minWidth: '100%',
                          padding: '10px',
                          border: '1px solid #E6EAF0'
                        }}
                        onChange={this.handleInputChange}
                      />
                    </div>

                    <span style={{ marginRight: '10px' }}></span>
                    <div style={{ flexBasis: '50%', marginTop: '16px' }}>
                      <span style={{ color: '#8F99A6' }}>
                        {' '}
                        {t('lastSeenEndDate')}{' '}
                      </span>
                      <input
                        name="last_seen_end_date"
                        value={last_seen_end_date || ''}
                        type="date"
                        style={{
                          height: '40px',
                          borderRadius: '3px',
                          minWidth: '100%',
                          padding: '10px',
                          border: '1px solid #E6EAF0'
                        }}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>

                  <ButtonsContainer>
                    <ClearButton onClick={this.handleRemoveAllFilters}>
                      {t('clearSelections')}
                    </ClearButton>
                    <SubmitButton onClick={this.filterLeads}>
                      {t('search')}
                    </SubmitButton>
                  </ButtonsContainer>
                </SearchRight>
              </SearchDropDown>
            </Popover>

            <NewCreateLeadBtn onClick={this.handleCreateLeadModalOpen}>
              <img src={PlusIcon} alt="plus-icon" /> {t('Create new lead')}
            </NewCreateLeadBtn>

            {/* <CreateLeadBtn onClick={this.handleCreateLeadModalOpen} /> */}
            <TaskDropDown />
            <ReminderDropDown />
          </LeadsTopSection>
          <Divider />
          <div
            style={{ display: 'flex', minHeight: '70px', alignItems: 'center' }}
          >
            <div style={{ flexBasis: '50%' }}>
              {total} {t('items')} | {t('updatedAago')}
            </div>

            {agentIds.length ? (
              <div style={{ display: 'flex', flexBasis: '50%' }}>
                <div style={{ width: '17%' }}>
                  <ReactSelect
                    placeholder={t('selectAgent')}
                    options={agentArray}
                    onChange={saveAssignedAgentInComp}
                  />
                </div>

                <button
                  onClick={() => sendLeadsList()}
                  style={{
                    background: '#FFC107',
                    color: '#003049',
                    cursor: 'pointer',
                    border: '1px solid #FFC107',

                    borderRadius: '6px',
                    width: '80px',
                    marginLeft: '10px'
                  }}
                >
                  {t('assign')}
                </button>
              </div>
            ) : null}
          </div>

          {isFetching ? (
            <Loader />
          ) : (
            <>
              <Table>
                {getTableHeader(isAdminUser, isManagerUser)}
                {this.getTableRows()}
              </Table>
            </>
          )}
          <PaginationContainer>
            <Pagination
              page={current_page}
              count={numOfPages}
              onChange={this.handlePageChange}
              color="secondary"
            />
          </PaginationContainer>
        </LeadsContainer>
        <CreateLead
          open={createLeadModalOpen}
          values={newLead}
          handleChange={this.handleCreateLeadInputChange}
          handleClose={this.handleCreateLeadModalOpen}
          handleCreate={this.handleCreateLead}
          handleCheck={this.handleCheck}
          checked={this.state.checked}
        />
      </WithSideMenu>
    )
  }
}

export default withTranslation()(Leads)
